
<template>
<div>
        <Header />
        <div class="contenter">
        <h1>关于EPS DATA新版网站上线的相关说明</h1>
        <h2>尊敬的各位用户：</h2>
        <p>感谢大家对EPS DATA新版网站的支持与关注。</p>
        <p>EPS DATA新版网站自2020年7月12日上线运行至今，已收到大量用户的反馈信息。这其中既有对此次改版工作的认同与肯定，也有关于此次改版工作的意见与建议。您们的积极反馈对我们来说，既是鞭策与激励，亦是我们不断向前的坚实基础与源源动力。在此，我们对大家关注度比较高的两个方面——新版EPS数据平台的“身份认证服务”与“个人账号服务”的相关事项做出进一步说明，希望对您有所帮助。</p>


        <h2>关于身份认证服务</h2>
        <p>所谓身份认证服务，是为了保障机构用户在IP范围外仍可正常访问平台所提供的一种增值服务，是EPS DATA创新产品服务体系的重要组成部分。</p>
        <p>此次新冠疫情对师生工作与学习的影响，促使我们重新审视现有的产品服务体系与服务模式。我们更加深刻地意识到打造全面、有效、可持续的产品服务体系与不断创新产品服务模式对推动学术资源体系建设、强化科学研究资源保障、增强环境风险防范能力至关重要。身份认证服务正是基于以上思考推出的一种积极、稳定、可操作的产品服务模式，它有效拓宽机构用户的校外访问途径，促进数据资源的高效利用，保障师生的科研活动不受客观环境的影响，也使我们能够在高校学术研究生态体系中，更好的为师生提供服务。</p>
        <h2>关于个人账号服务</h2>
        <p>新版EPS数据平台重构了个人数据中心。用户可以将数据检索、数据分析与数据应用的过程信息收藏在个人数据中心中，更加贴近用户的实际需求，也对用户实证研究的便捷性与延续性有很大帮助。身份认证账号单日数据下载额度高达10万条，可以更好保障用户的研究需要。</p>
        <p>我们鼓励用户注册并使用个人账号，这也将更有助于我们在未来为您提供更加细致与精准的个性化服务。</p>
        <p>以上为EPS DATA对近日用户集中关注问题的相关说明，希望可以让您更好的了解我们。</p>
        
        <p>十多年来，EPS DATA始终以用户需求为核心，一切工作与改进方向都是围绕这个核心开展进行。今天，EPS DATA取得的些许成绩是因为我们始终坚持这个核心思想不曾动摇，通过技术赋能，驱动产品与服务不断创新，致力于为用户创造更多、更大的价值。</p>
        <p>再一次感谢广大用户对EPS DATA新版网站的反馈！我们仍将坚定信念并始终如一，全心全意地为用户提供简单、专业、高品质的数据服务。</p>
        <p class="a-right">EPS DATA团队</p>
        <p class="a-right">2020年7月20日</p>

    </div>
        <Footer />

</div>
    
</template>

<script>
import Header from "./commons/Header.vue";
import Footer from "./commons/Footer.vue";
export default {
    name:'Statements',
    components: {
    Header,
    Footer
  },
        
}
</script>

<style scoped lang="scss">
    .contenter {
        width:1200px;
        margin:10px auto;
        font-size:16px;
        background:none;
        h1 {
            text-align: center;
            line-height:80px;
            font-size:24px;
            padding-top:20px;
        }
        h2 {
            margin:15px 0;
            line-height: 30px;
            font-size:16px;
        }
        p {
            line-height: 30px;
            margin:10px 0;
            text-indent: 32px;
            font-size:16px;
        }
        .a-right{
            text-align: right;
        }
        h2 span,p span {
            display: inline-block;
            width:30px;
        }
    }
</style>